/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react";
import { Helmet, HelmetProps } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import useConsole from '../hooks/useConsole'
interface SEOProps extends Pick<HelmetProps, 'meta'> {
  description?: string;
  lang?: string;
  title: string;
}

function SEO({ description, lang, meta, title }: SEOProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );
  useConsole();

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={meta?.concat([
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `og:image`,
          content: "/img-meta-og.png",
        },
      ])}
    >
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://hyperconnect.com/",
            "name": "Hyperconnect",
            "description": ${metaDescription},
            "sameAs": [
              "https://www.instagram.com/hyperconnect",
              "https://www.facebook.com/hpcnt",
              "https://www.linkedin.com/company/hyperconnect",
              "https://hyperconnect.github.io/"
            ],
            "address": "20F, ASEM Tower, 517, Yeongdong-daero, Gangnam-gu, Seoul, Republic of Korea",
            "logo": "/img-meta-og.png",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+82-2-562-8880",
              "contactType": "Fax"
            }
          }
      `}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default SEO;