import React, { useEffect } from "react";
const useConsole = () => {
  useEffect(() => {
    console.log(
      `
    %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c                             
    %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c  _________________________  
    %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c /                         \\ 
    %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c |  %c Aim High, Move Fast 🚀%c| 
    %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c  %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c\\_________________________/ 
    %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c  %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c  /                         
    %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c                             
    %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c                             
    %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c                             
    %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c                             
    %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c                             
    %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c                             
    %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c                             
    %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c                             
    %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c https://career.hpcnt.com/  
    %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c %c                             
    `,
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);color:rgba(23,218,158,255)",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "color:rgba(255,255,255,255);font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(23,218,158,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(23,218,158,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(0,0,0,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(0,0,0,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-weight:800; font-size: 13px; background:white",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);",
      "font-size: 13px; background: linear-gradient(rgba(255,255,255,255) 0% 50%, rgba(255,255,255,255) 50% 100%);"
    );
  }, []);
};

export default useConsole;
